import React,{useState} from 'react';
import Modal from './Modal';
import emailjs from 'emailjs-com';
import { toast, Toaster } from 'react-hot-toast';



const SectionA = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModal = ()=>{
    setShowModal(!showModal);
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: '',
  });

  // Handle form inputs change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send('service_347vnps', 'template_sn3quy6', formData, 'bBPB-aSOhZg_721dp')
      .then(
        (result) => {
          toast.success('Form submitted successfully! Thank you.',{
            style: {
                background: 'green',
                color: 'white',
                fontSize: '16px',
                padding: '13px 10px',
                borderRadius: '8px',
            }
        });

         // Clear form data
         setFormData({
          name: '',
          email: '',
          phone: '',
          query: '',
        });

        },
        (error) => {
          toast.error(`There was an error submitting the form: ${error.message}`,{
            style: {
                background: 'red',
                color: 'white',
                fontSize: '16px',
                padding: '13px 10px',
                borderRadius: '8px',
            }
        });
        }
      );

    // Close the modal after submission
    handleModal();
  };


  return (
    <div className="w-full h-full lg:min-h-[100vh] pt-[80px] bg-[#F8EDED]">
      <Toaster position="top-right" reverseOrder={false} />
      <div className='w-full h-auto max-w-[1280px] m-auto'>
        <div className="grid grid-cols-1 p-[20px] md:p-[50px]">

        {/* Left Section */}
        <div className="flex items-start justify-center box-border col-span-1">
          <div className="h-full pb-10 w-full">
            <div className="text-[36px] md:text-[48px] font-semibold text-center text-[#173B45] px-4 md:px-10 md:pt-8 pt-10">
              Transform your IT with <span className='text-[#FF8225]'>Technoforges Pvt Ltd</span>
            </div>
            <p className="w-full text-[16px] md:text-[22px] leading-8 mt-8 text-center px-4 md:px-10 text-[#173B45]">
              Technoforges Pvt Ltd specializes in advanced IT consulting, cloud migration, and staffing solutions. Our mission is to deliver customized services that boost efficiency and drive business growth. Partner with us to enhance your IT strategy and achieve your business goals.
            </p>
            <div className="flex items-center justify-center mt-10">
              <a
                className="text-[#F8EDED] font-bold text-[18px] md:text-[24px] bg-[#FF8225] duration-400 rounded-[12px] text-center px-4 py-3 md:min-w-[280px] md:min-h-[60px] min-w-[163px] min-h-[51px] cursor-pointer hover:scale-[1] hover:bg-[#f79952] hover:text-[#B43F3F] sectionAButton"
                onClick={handleModal}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        </div>

        {/* Modal */}
        <Modal isOpen={showModal} onClose={handleModal}>
          <form
            className="space-y-4 px-2 pt-6 pb-6 lg:px-4 lg:pt-6 lg:pb-6"
            onSubmit={handleSubmit}
          >
            <p className="w-full h-auto text-[22px] font-bold text-[#B43F3F]">
              Get started by filling in the details below:
            </p>
            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Name <span className='text-[red]'>*</span>
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Email <span className='text-[red]'>*</span>
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Phone No (Optional)
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="number"
                placeholder="Phone No"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Query <span className='text-[red]'>*</span>
              </label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Query"
                name="query"
                value={formData.query}
                onChange={handleChange}
                rows="4"
                required
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-[#FF8225] text-white font-bold rounded-md hover:bg-[#B43F3F] transition duration-300"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>


      </div>
    </div>
  );
};

export default SectionA;
