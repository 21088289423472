import React, { useRef, useEffect, useState } from 'react';
import Header from "./Page/Header";
import SectionA from "./Page/SectionA";
import SectionB from './Page/SectionB';
import SectionC from './Page/SectionC';
import SectionD from './Page/SectionD';
import SectionE from './Page/SectionE';
import Footer from './Page/Footer';
import '../src/css/style.css';

function App() {
  const sectA = useRef(null);
  const sectB = useRef(null);
  const sectC = useRef(null);
  const sectD = useRef(null);
  const sectE = useRef(null);

  const scrollToSection = (sectionRef) => {
    const headerOffset = 80; // Adjust this value according to your header height
    const elementPosition = sectionRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  // Scroll to Section A on initial page load
  useEffect(() => {
    scrollToSection(sectA);
  }, []);

  const [sectionName, setSectionName] = useState('');

  const handleSectionName = (value) => {
    setSectionName(value);
  };

  // useEffect(() => {
  //   console.log("Current Section:", sectionName);
  //   // Perform any additional actions based on the updated sectionName
  // }, [sectionName]);

  return (
    <div className="w-full h-full">
      <Header
        scrollToSection={scrollToSection}
        getSectionName={handleSectionName}
        sectA={sectA}
        sectB={sectB}
        sectC={sectC}
        sectD={sectD}
        sectE={sectE}
      />

      <div ref={sectA}>
        <SectionA />
      </div>

      <div ref={sectB} className="w-full h-full">
        <SectionB />
      </div>

      <div ref={sectC} className="w-full h-full">
        <SectionC value={sectionName} />
      </div>

      <div ref={sectD} className="w-full h-full">
        <SectionD />
      </div>

      <div ref={sectE} className="w-full h-full">
        <SectionE />
      </div>

      <div className="min-h-[300px]">
        <Footer scrollToSection={scrollToSection} sectA={sectA} />
      </div>
    </div>
  );
}

export default App;
