import React from 'react';
import Logo from "../Static/Logo.png";

const Footer = ({ scrollToSection, sectA }) => {
  return (
    <div className='w-full h-full bg-[#FF8225] min-h-[300px] flex justify-center items-center'>
        <div className='w-full h-full max-w-[1280px] m-auto'>
        <div className='w-full h-full grid grid-cols-1 items-center justify-center'>
            <div className='w-full h-auto'>
                <div className="flex items-center justify-center">
                    <button 
                        className="w-[146px] cursor-pointer  text-[#B43F3F] text-[48px] font-bold flex flex-row justify-center gap-1 items-center" 
                        onClick={() => scrollToSection(sectA)}
                    >
                        <img src={Logo}  className='w-[40x] h-[40px]'/>Technoforges
                    </button>
                </div>
                <div className='w-[400px] m-auto h-full flex justify-center items-center gap-6 px-2 py-4'>
                    <a href='#' className='text-[#F5F5F5] text-[18px] hover:underline'>Policy</a>
                    <a href='#' className='text-[#F5F5F5] text-[18px] hover:underline'>Terms</a>
                    <a href='#' className='text-[#F5F5F5] text-[18px] hover:underline'>Privacy</a>
                </div>
                <div className='block text-center h-full'>
                    <p className='text-sm text-[#211d1d]'>©2024 Technoforges. All rights reserved.</p>
                </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Footer;
