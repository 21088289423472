import React, { useEffect, useRef, useState } from 'react';
import IT_Consulting from '../Static/SectionC/IT_Consulting.jpeg';
import Cloud_migration from '../Static/SectionC/Cloud_migration.jpeg';
import Augmented from '../Static/SectionC/Augmented.jpeg'

const SectionC = ({ value }) => {
  const [sectCvalue, setsectCvalue] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (value === 'sectC') {
      setsectCvalue(true); 
      setHasEntered(true);
    } else {
      setsectCvalue(false);
      setHasEntered(false);
    }
  }, [value]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!hasEntered) {
            // console.log('1');
            setHasEntered(true);
          }
        } else {
          if (hasEntered) {
            // console.log('leave');
            setHasEntered(false);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // viewport
      rootMargin: '0px',
      threshold: [0, 1] // triggers at any visible amount and fully visible
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasEntered]);

  return (
    <div ref={sectionRef} className='w-full h-full bg-[#F8EDED]'> 
      <div className='w-full h-full max-w-[1280px] m-auto'>
        <div className='w-full h-auto p-8 lg:p-10'>
          <div className='w-full h-auto mt-4 lg:mt-0'>
            <p className='w-full h-auto text-[48px] text-center text-[#173B45]'>
              Our
              <span className='w-full h-auto text-[48px] text-[#FF8225]'> Offerings</span>
            </p>
          </div>
          <div className={`w-full h-full mt-6 mb-6 grid grid-cols-1 lg:grid-cols-2 gap-8 min-h-[600px] ${hasEntered ?'overflow-hidden': ''}`}>
            <div className={`w-full h-full flex justify-start items-center col-span-1 min-h-[100px] duration-[4s] ${hasEntered ? 'returncard' : 'card' }`}>
              <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 rounded-md overflow-hidden shadow-lg' style={{ background: 'linear-gradient(225deg, #FF8225 0%, #F4E5E5 100%)' }}>
                <div className='w-full h-full col-span-1 p-6 flex flex-col items-start justify-center'>
                  <p className='w-full h-auto text-[22px] font-bold mt-4 uppercase text-[#B43F3F]'>IT Consulting</p>
                  <p className='w-full h-auto text-[18px] leading-6 mt-2 text-[#173B45]'>Expert Solution in SAP, Salesforce, and more, designed for your business.</p>
                </div>
                <div className='w-full h-full col-span-1 flex justify-end items-center'>
                  <img src={IT_Consulting} alt="Visual Representation" className="h-[300px] w-[400px] object-cover" />
                </div>
              </div>
            </div>

            <div className={`w-full h-full flex justify-start items-center col-span-1 duration-[2s] min-h-[200px] ${hasEntered ? 'returncard' : 'card' }`}>
              <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 bg-[#E8EAF6] rounded-md overflow-hidden shadow-lg' style={{ background: 'linear-gradient(225deg, #FF8225 0%, #F4E5E5 100%)' }}>
                <div className='w-full h-full col-span-1 p-6 flex flex-col items-start justify-center'>
                  <p className='w-full h-auto text-[22px] font-bold mt-4 uppercase text-[#B43F3F]'>Cloud Migration</p>
                  <p className='w-full h-auto text-[18px] leading-6 mt-2 text-[#173B45]'>Seamless transitions to AWS, Azure, or GCP.</p>
                </div>
                <div className='w-full h-full col-span-1 flex justify-end items-center'>
                  <img src={Cloud_migration } alt="Visual Representation" className="h-[300px] w-[400px] object-cover" />
                </div>
              </div>
            </div>

            <div className={`w-full h-full flex justify-start items-center col-span-1 lg:col-span-2 lg:w-[50%] lg:m-auto lg:h-0 z-[1] ${hasEntered ? 'returncard2':'card2'}`}>
              <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 bg-[#E8EAF6] rounded-md overflow-hidden shadow-lg' style={{ background: 'linear-gradient(225deg, #FF8225 0%, #F4E5E5 100%)' }}>
                <div className='w-full h-full col-span-1 p-6 lg:p-8 flex flex-col items-start justify-center'>
                  <p className='w-full h-auto text-[22px] font-bold mt-4 uppercase text-[#B43F3F]'>Augmented Staffing</p>
                  <p className='w-full h-auto text-[18px] leading-6 mt-2 text-[#173B45]'>Flexible staffing solutions to meet your IT project requirements.</p>
                </div>
                <div className='w-full h-full col-span-1 flex justify-end items-center'>
                  <img src={Augmented} alt="Visual Representation" className="h-[300px] w-[400px] object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionC;
