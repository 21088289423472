import React, { useState } from 'react';
import { GiHamburgerMenu as MenuIcon } from 'react-icons/gi';
import { IoCloseSharp as CloseIcon } from 'react-icons/io5';
import Logo from "../Static/Logo.png";

const Header = ({ scrollToSection, getSectionName, sectA, sectB, sectC, sectD, sectE }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSectionClick = (sectionRef, sectionName) => {
    scrollToSection(sectionRef);
    getSectionName(sectionName);
  };

  return (
    <div className="w-full">
      {/* Desktop navbar */}
      <div className="fixed top-0 left-0 w-full h-[80px] flex justify-between items-center box-border px-6 md:px-10 z-[1000] bg-[#FF8225] border-b-2 border-[#173B45]">
        <div className="grid items-center">
          <button
            className="w-[146px] cursor-pointer text-[#B43F3F] text-[26px] font-bold flex flex-row justify-start items-center gap-1"
            onClick={() => handleSectionClick(sectA, 'sectA')}
          >
            <img src={Logo} className='w-[40x] h-[40px]'/>Technoforges
          </button>
        </div>
        <div className="hidden lg:flex space-x-8">
          <button
            className="text-[14px] font-semibold cursor-pointer uppercase text-[#F8EDED] hover:text-[#B43F3F]"
            onClick={() => handleSectionClick(sectA, 'sectA')}
          >
            Home
          </button>
          <button
            className="text-[14px] font-semibold cursor-pointer uppercase text-[#F8EDED] hover:text-[#B43F3F]"
            onClick={() => handleSectionClick(sectB, 'sectB')}
          >
            Our Principles
          </button>
          <button
            className="text-[14px] font-semibold cursor-pointer uppercase text-[#F8EDED] hover:text-[#B43F3F]"
            onClick={() => handleSectionClick(sectC, 'sectC')}
          >
            Our Offerings
          </button>
          <button
            className="text-[14px] font-semibold cursor-pointer uppercase text-[#F8EDED] hover:text-[#B43F3F]"
            onClick={() => handleSectionClick(sectD, 'sectD')}
          >
            Benefits
          </button>
          <button
            className="text-[14px] font-semibold cursor-pointer uppercase text-[#F8EDED] hover:text-[#B43F3F]"
            onClick={() => handleSectionClick(sectE, 'sectE')}
          >
            Contact Us
          </button>
        </div>
        <div className="lg:hidden flex justify-end items-center border border-[gray]">
          <button onClick={toggleMenu} className="p-2 border border-black">
            {isOpen ? <CloseIcon className="text-2xl text-[#F8EDED]" /> : <MenuIcon className="text-2xl text-[#F8EDED]" />}
          </button>
        </div>
      </div>

      {/* Mobile navbar */}
      <div
        className={`fixed top-[80px] left-0 w-full bg-[#f0c9c9] transition-max-height duration-500 ease-in-out overflow-hidden lg:hidden ${isOpen ? 'max-h-[400px]' : 'max-h-0'}`}
      >
        <ul className="flex flex-col space-y-4 p-4" style={{zIndex:'2000'}}>
          <li className='border-b-2 border-[#FF8225] py-4'>
            <button
              className="text-lg font-semibold pl-4 uppercase text-[#173B45] hover:text-[#FF8225]"
              onClick={() => { handleSectionClick(sectA, 'sectA'); toggleMenu(); }}
            >
              Home
            </button>
          </li>
          <li className='border-b-2 border-[#FF8225] py-4'>
            <button
              className="text-lg font-semibold pl-4 uppercase text-[#173B45] hover:text-[#FF8225]"
              onClick={() => { handleSectionClick(sectB, 'sectB'); toggleMenu(); }}
            >
              Our Principles
            </button>
          </li>
          <li className='border-b-2 border-[#FF8225] py-4'>
            <button
              className="text-lg font-semibold pl-4 uppercase text-[#173B45] hover:text-[#FF8225]"
              onClick={() => { handleSectionClick(sectC, 'sectC'); toggleMenu(); }}
            >
              Our Offerings
            </button>
          </li>
          <li className='border-b-2 border-[#FF8225] py-4'>
            <button
              className="text-lg font-semibold pl-4 uppercase text-[#173B45] hover:text-[#FF8225]"
              onClick={() => { handleSectionClick(sectD, 'sectD'); toggleMenu(); }}
            >
              Benefits
            </button>
          </li>
          <li className='py-4'>
            <button
              className="text-lg font-semibold pl-4 text-[#173B45] hover:text-[#FF8225]"
              onClick={() => { handleSectionClick(sectE, 'sectE'); toggleMenu(); }}
            >
              Contact us
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
