import React from 'react';

const SectionE = () => {
  return (
    <div className="w-full h-full lg:min-h-[100vh] py-[70px] bg-[#F7F9FC]"> {/* Updated background color */}
        <div className='w-full h-full max-w-[1280px] m-auto'>
        <div className='w-full h-full grid items-center grid-cols-1'>
            <div className="w-full h-full flex flex-col gap-6 justify-start items-center px-10 pb-[60px]">
                
                {/* Contact Information */}
                <div className="w-full h-auto text-center mt-[60px] px-0 lg:px-[100px]">
                    <p className="text-[18px] text-[#173B45]"> {/* Updated text color */}
                        Ready to elevate your IT capabilities?<br/>
                        Reach out to Technoforges Pvt Ltd at 
                        <a href="mailto:basakkaustuv@technoforges.com" className="text-[#B43F3F] hover:underline ml-2 mr-2">
                        hello@technoforges.com 
                        </a>
                    </p>
                </div>

                {/* Company Slogan */}
                <div className="w-full h-auto border-t-2 border-b-2 border-[#FF8225] py-4 mt-[60px] px-0 lg:px-[80px]"> {/* Updated border color */}
                    <p className="text-[22px] font-bold text-[#B43F3F] text-center"> {/* Updated text color */}
                        Technoforges Pvt Ltd: Your partner in driving IT excellence and growth.
                    </p>
                </div>
                
            </div>
        </div>
        </div>
      
    </div>
  );
}

export default SectionE;
