import React from 'react';
import cutomer_centric from '../Static/SectionB/cutomer_centric.png';
import Innovation from '../Static/SectionB/Innovation.png';
import Transparent from '../Static/SectionB/Transparent.png';

const SectionB = () => {
  const datas = [
    { image: cutomer_centric, content1: 'Client-Centric', content2: 'We offer tailored solutions to meet your specific needs.' },
    { image: Innovation, content1: 'Innovative', content2: 'Utilizing the latest technology to deliver superior results.' },
    { image: Transparent, content1: 'Transparent', content2: 'Committed to clear communication and ethical practices.' },
  ];

  return (
    <div className='w-full h-full lg:min-h-[100vh] bg-[#F4E5E5] scroll-container'> 
      <div className='w-full h-full max-w-[1280px] m-auto'>
      <div className='w-full h-full flex justify-center items-start flex-col px-[30px] py-[30px] md:px-[50px] md:py-[30px]'>
        <p className='w-full h-auto text-[48px] font-extrabold text-[#173B45]'>Our <span className='text-[#FF8225]'>Principles</span></p>
        <div className='w-full h-full min-h-[340px] grid grid-cols-1 lg:grid-cols-3 gap-[50px] mt-6 mb-6'>
          {datas.map((item, index) => {
            return (
              <div className='col-span-1 flex flex-row justify-center items-center priciple_card duration-200 cursor-pointer' key={index}>
                <div className='min-h-[300px] w-full px-8 py-8 items-center justify-center flex flex-col rounded-[12px] border-b-2 border-[#173B45] bg-[#e7ba98] text-center hover:bg-[#e6a16d]'>
                {/* style={{ background: 'linear-gradient(225deg, #FF8225 0%, #F4E5E5 100%)' }} */}
                  <img src={item.image} className='w-[82px] h-[80px] mb-1' />
                  <p className='w-full h-auto text-[22px] font-bold mt-4 uppercase text-[#B43F3F]'>{item.content1}</p>
                  <p className='w-full h-auto text-[18px] leading-6 mt-2 text-[#173B45]'>{item.content2}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      {/* <div className='w-full h-full grid grid-cols-1 lg:grid-cols-2 gap-0 mt-6'>
            <div className='w-full h-auto'>
                <div className='w-full h-auto grid grid-cols-2'>
                    <div className='w-full min-h-[300px] bg-white border border-[lightgray] flex justify-center items-center flex-col'>
                      <p className='text-[22px] text-[#173B45]'>Total</p>
                      <p className='text-[22px] text-[#FF8225]'>30000+</p>   
                    </div>
                    <div className='w-full min-h-[300px] bg-white border border-[lightgray] flex justify-center items-center flex-col'>
                      <p className='text-[22px] text-[#173B45]'>Total</p>
                      <p className='text-[22px] text-[#FF8225]'>30000+</p>   
                    </div>
                    <div className='w-full min-h-[300px] bg-white border border-[lightgray] flex justify-center items-center flex-col'>
                      <p className='text-[22px] text-[#173B45]'>Total</p>
                      <p className='text-[22px] text-[#FF8225]'>30000+</p>   
                    </div>
                    <div className='w-full min-h-[300px] bg-white border border-[lightgray] flex justify-center items-center flex-col'>
                      <p className='text-[22px] text-[#173B45]'>Total</p>
                      <p className='text-[22px] text-[#FF8225]'>30000+</p>   
                    </div>
                </div>
            </div>
            <div className='w-full h-auto'>
                <div className='w-full h-full flex justify-center items-center'>
                    <img src="https://img.freepik.com/free-photo/businesman-technological-scene_1134-492.jpg?t=st=1725289237~exp=1725292837~hmac=4a4cfdf9c4e1bcd922e505be25da15ca31d22b181a6c3e51b93f726e8fe3f3a0&w=826" className='w-full h-full '/>
                </div>
            </div>
        </div> */}

      </div>
    </div>
  );
};

export default SectionB;
