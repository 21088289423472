import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 5000;
`;




const ModalContainer = styled.div`
  background: #eaa36d;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: absolute;
  padding: 10px 30px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  
  @media (max-width: 1024px) {
    min-width: 600px;
    height: auto;
  }

  @media (max-width: 764px) {
    max-width: 90%; /* Overrides the max-width from the broader screen media query */
    min-width: initial; /* Resets min-width to ensure it doesn't conflict with the broader screen */
    width: 100%;
    height: auto; /* Make the height flexible for mobile devices */
  }
`;



const CloseButton = styled.button`
  position: absolute;
  top: -5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
`;

const Modal = ({ isOpen, onClose, size, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable body scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable body scroll
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = 'auto'; // Ensure body scroll is enabled on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
   
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer size={size}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <div>
        {children}
        </div>
      </ModalContainer>
    </ModalBackdrop>
  
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  modalBgColor: PropTypes.string,
};



export default Modal;
