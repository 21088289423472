import React, { useEffect,useState,useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Customer_Solution from '../Static/SectionD/Customer_Solution.png';
import Expertise_Innovation from '../Static/SectionD/Expertise_Innovation.png';
import Improved_Efficiency from '../Static/SectionD/Improved_Efficiency.png';
import Benifits from '../Static/Benifits.jpeg';
import emailjs from 'emailjs-com';
import { toast, Toaster } from 'react-hot-toast';
import Modal from './Modal';

const SectionD = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModal = ()=>{
    setShowModal(!showModal);
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: '',
  });

  // Handle form inputs change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send('service_347vnps', 'template_sn3quy6', formData, 'bBPB-aSOhZg_721dp')
      .then(
        (result) => {
          toast.success('Form submitted successfully! Thank you.',{
            style: {
                background: 'green',
                color: 'white',
                fontSize: '16px',
                padding: '13px 10px',
                borderRadius: '8px',
            }
        });

         // Clear form data
         setFormData({
          name: '',
          email: '',
          phone: '',
          query: '',
        });

        },
        (error) => {
          toast.error(`There was an error submitting the form: ${error.message}`,{
            style: {
                background: 'red',
                color: 'white',
                fontSize: '16px',
                padding: '13px 10px',
                borderRadius: '8px',
            }
        });
        }
      );

    // Close the modal after submission
    handleModal();
  };


    useEffect(() => {
        AOS.init({
          duration: 1000, // Duration of the animation (5 seconds)
          once: false,    // Allows the animation to repeat on every scroll
          offset: 60,
        });
        // AOS.refresh();    // Refresh AOS to detect changes dynamically
      }, []);
      

  return (
    <div className='w-full h-full bg-[#F4E5E5]'>
      <div className='w-full h-full max-w-[1280px] m-auto border-2 border-blue'>
        
        {/* Section 2 */}
        <div className='w-full h-full px-10 py-[80px] border-b-1 border-[#D0D7E5] bg-[#F4E5E5]'>
          <div className='w-full h-full flex justify-start items-center'>
            <p className='w-full h-auto text-[48px] pl-2 md:pl-10 text-[#FF8225]'><span className='text-[#173B45]'>Be</span>nefits</p>
          </div>

          <div className='w-full h-full grid grid-cols-1 md:grid-cols-3 min-h-[220px]'>

            {/* Card 1: Fade Left */}
            <div
              className='w-full h-full col-span-1 px-2 py-2 lg:px-6 md:py-6 border-b-2 md:border-b-0 border-r-0 md:border-r-2 border-[#FF8225]'
              data-aos='zoom-in'
            >
              <div className='w-full h-full min-h-[200px] flex flex-col justify-center md:justify-start items-start p-2 lg:p-4'>
                <img src={Customer_Solution} className='w-[90px] h-[80px]' alt='Customized Solutions' />
                <p className='text-[22px] w-full font-semibold leading-tight mt-4 text-[#B43F3F]'>Customized Solutions</p>
                <p className='text-[18px] mt-1 text-[#173B45]'>Services tailored specifically to your business needs.</p>
              </div>
            </div>

            {/* Card 2: Zoom In */}
            <div
              className='w-full h-full col-span-1 px-2 py-2 lg:px-6 md:py-6 border-b-2 md:border-b-0 border-r-0 md:border-r-2 border-[#FF8225]'
              data-aos='zoom-in'
            >
              <div className='w-full h-full min-h-[200px] flex flex-col justify-center md:justify-start items-start p-2 lg:p-6'>
                <img src={Expertise_Innovation} className='w-[80px] h-[85px]' alt='Expertise & Innovation' />
                <p className='text-[22px] w-full font-semibold leading-tight mt-4 text-[#B43F3F]'>Expertise & Innovation</p>
                <p className='text-[18px] mt-1 text-[#173B45]'>Cutting edge technology and industry-leading expertise.</p>
              </div>
            </div>

            {/* Card 3: Fade Right */}
            <div
              className='w-full h-full col-span-1 px-2 py-2 lg:px-6 md:py-6'
             data-aos='zoom-in'
            >
              <div className='w-full h-full min-h-[200px] flex flex-col justify-center md:justify-start items-start p-2 lg:p-6'>
                <img src={Improved_Efficiency} className='w-[80px] h-[80px]' alt='Improved Efficiency' />
                <p className='text-[22px] w-full font-semibold leading-tight mt-4 text-[#B43F3F]'>Improved Efficiency</p>
                <p className='text-[18px] mt-1 text-[#173B45]'>Streamlined processes for faster growth and better results.</p>
              </div>
            </div>

          </div>
        </div>

        {/* Section 1 */}
        <div className='w-full h-full grid grid-cols-1 md:grid-cols-2'>
          <div className='w-full h-full col-span-1'>
            <div className='w-full h-full flex justify-center items-center'>
              <img src={Benifits} className='w-full h-full' alt='Benefits' />
            </div>
          </div>
          <div className='w-full h-full col-span-1 p-4 bg-[#eaa36d]'>
            <div className='w-full h-full pl-6 py-10 flex flex-col items-start justify-center gap-0'>
              <p className='text-[48px] w-full font-medium leading-tight text-[#B43F3F]'>Ready to accelerate your business growth?</p>
              <p className='text-[22px] mt-6 text-[#173B45] lg:typing'>Tell us how we can empower you to reach success even faster!</p>
              <a
                className="text-[#F8EDED] font-bold text-[18px] md:text-[24px] bg-[#FF8225] duration-400 rounded-[12px] text-center px-4 py-3 md:min-w-[280px] md:min-h-[60px] min-w-[163px] min-h-[51px] cursor-pointer hover:scale-[1] hover:bg-[#f79952] hover:text-[#B43F3F] z-[100] sectionAButton mt-8"
                onClick={handleModal}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

      </div>

        {/* Modal */}
        <Modal isOpen={showModal} onClose={handleModal}>
          <form
            className="space-y-4 px-2 pt-6 pb-6 lg:px-4 lg:pt-6 lg:pb-6"
            onSubmit={handleSubmit}
          >
            <p className="w-full h-auto text-[22px] font-bold text-[#B43F3F]">
              Get started by filling in the details below:
            </p>
            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Name <span className='text-[red]'>*</span>
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Email <span className='text-[red]'>*</span>
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Phone No (Optional)
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="number"
                placeholder="Phone No"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#173B45] mb-1">
                Query <span className='text-[red]'>*</span>
              </label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Query"
                name="query"
                value={formData.query}
                onChange={handleChange}
                rows="4"
                required
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-[#FF8225] text-white font-bold rounded-md hover:bg-[#B43F3F] transition duration-300"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
    </div>
  );
};

export default SectionD;
